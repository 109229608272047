@import "@assets/utils.scss";

.horizontal-select {
  display: flex;

  &__option {
    $fontSize: 13px;
    display: table-cell;
    padding: getEm(9px, $fontSize) getEm(16px, $fontSize);
    font: 600 $fontSize/getEm(20px, $fontSize)  $fontNotoSans;
    color: $neutrals1;
    text-decoration: none;
    border: getEm(1px, $fontSize) solid $neutrals1;
    background: $neutrals6;
    transition:
            background-color 0.3s ease-in-out,
            color 0.3s ease-in-out;
    cursor: pointer;

    &:not(:first-child) {
      border-left: none;
    }

    &:first-child {
      border-radius: getEm(8px, $fontSize) 0 0 getEm(8px, $fontSize);
    }

    &:last-child {
      border-radius: 0 getEm(8px, $fontSize) getEm(8px, $fontSize) 0;
    }

    &:hover {
      background: $neutrals4;
    }

    &_selected, &_selected:hover {
      color: $neutrals6;
      background: $neutrals1;
    }
  }
}
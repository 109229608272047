@import '@assets/utils';
@import '@assets/scss/general/field';
@import '@assets/scss/general/text';

.react-datepicker{
  font-size: 16px;
  border: none;
  border-radius: getEm(16px);
  box-shadow: 0 getEm(4px) getEm(8px) rgba(0, 0, 0, 0.08);

  &-wrapper {
    display: block;
  }

  &__month-container {
    padding: getEm(16px);
  }

  &__month {
    margin: 0;
  }

  &__header{
    margin: 0 0 getEm(8px) 0;
    padding: 0;
    border: none;
    background: #fff;
  }

  &__triangle {
    display: none;
  }

  &__current-month {
    $fontSize: 16px;
    margin: 0 0 getEm(16px) 0;
    // having 24px height, arrows are absolutely positioned but shouldn't be by design
    font: 600 $fontSize/getEm(24px, $fontSize) $fontNotoSans;
    color: $neutrals2;
  }

  &__navigation {
    $font-size: 16px;
    display: block;
    padding: 0;
    width: getEm(24px, $font-size);
    height: getEm(24px, $font-size);
    font-size: $font-size;
    border: none;
    background:  $neutrals2 no-repeat center;
    mask-image: url("~assets/svg/chevron-down.svg");

    &--previous {
      top: getEm(16px, $font-size);
      left: getEm(16px, $font-size);
      transform: rotateZ(90deg);
    }

    &--next {
      top: getEm(16px, $font-size);
      right: getEm(16px, $font-size);
      transform: rotateZ(-90deg);
    }
  }

  &__day {
    $fontSize: 13px;
    margin: 0;
    padding: getEm(6px, $fontSize) 0;
    width: getEm(40px, $fontSize);
    font: $fontSize/getEm(20px, $fontSize) $fontNotoSans;
    border-radius: getEm(4px, $fontSize);
    outline: none;
    user-select: none;
    transition:
            background-color 0.3s ease-in-out,
            color 0.3s ease-in-out;

    &:hover {
      background-color: $primary5;
    }

    &--selected{
      color: $neutrals6;
      background: $primary3;

      &:hover {
        background: $primary2;
      }
    }

    &--keyboard-selected {
      color: #000;
      background: none;
    }

    &--outside-month {
      color: $neutrals3;
      pointer-events: none;
    }

    &--in-range {
      color: $neutrals6;
      background: $primary3;

      &:hover {
        background: $primary2;
      }
    }

    &--in-selecting-range {
      color: #000;
      background: $primary5;
    }

    &--selecting-range-start {
      color: $neutrals6;
      background: $primary3;

      &:hover {
        background: $primary2;
      }
    }
  }

  &__day-name {
    $fontSize: 13px;
    margin: 0;
    width: getEm(40px, $fontSize);
    font: $fontSize/getEm(20px, $fontSize) $fontNotoSans;
    color: $neutrals2;
  }
}

.datepicker {

  &__picker {
    &_with_error {
      .datepicker__input {
        border: getEm(1px) solid $systemDanger1;
      }
    }
  }

  &__header {
    @extend .field-container-position;
    font-size: $fontSize;
  }

  &__label {
    @extend .input-label;
  }

  &__input {
    @extend .field-padding;
    @extend .field-value-font;
    @extend .field-border;
    padding: getEm(9px, $fontSize) getEm(40px + 32px, $fontSize) getEm(9px, $fontSize) getEm(12px, $fontSize);
    width: 100%;
    font-size: $fontSize;

    &::placeholder {
      @extend .field-placeholder-font;
    }

    &:focus {
      border: getEm(1px, $fontSize) solid $primary1;
    }
  }

  &__icon {
    @extend .field-icon-position;
    @extend .field-icon-padding;
    @extend .field-icon-color;
    padding: getEm(8px, $fontSize) getEm(12px, $fontSize) getEm(8px, $fontSize) getEm(4px, $fontSize);
    font-size: $fontSize;
  }

  &__close-button {
    @extend .field-button-position;
    @extend .field-button-padding;
    @extend .field-button-color;
    @extend .field-button-size;
    @extend .field-button-visibility;
    right: getEm(40px, $fontSize);
    padding: getEm(8px, $fontSize) getEm(4px, $fontSize);

    &:hover {
      @extend .hovered-field-button-color;
    }

    &_visible {
      @extend .field-button-visibility_visible;
    }

    &_hidden {
      @extend .field-button-visibility_hidden;
    }
  }

  &__error-container {
    @extend .field-error-container-margin;
  }

  &__error {
    @extend .error-text-font;
  }
}
@import '@assets/utils';

.auth-form {
  $textFz: 16px;
  border-radius: getEm(8px);
  overflow: hidden;

  &__header, &__form {
    padding: getEm(40px) getEm(48px);
    box-sizing: border-box;
  }

  &__header {
    background: $primary3;
  }

  &__form {
    background: $neutrals6;
  }

  &__text-font {
    font: $textFz/getEm(22px, $textFz) $fontNotoSans;
    color: $neutrals2;
  }

  &__form-item {
    &:not(:first-child) {
      margin: getEm(24px, $textFz) 0 0 0;
    }
  }

  &__text {
    @extend .auth-form__text-font;
    @extend .auth-form__form-item;
  }

  &__redirect {
    @extend .auth-form__text;
    padding: 0;
    color: $primary2;
    border: none;
    background: none;
    transition: color 0.3s ease-in-out;
    cursor: pointer;

    &:not(:first-child) {
      margin: 0 0 0 getEm(16px, $textFz);
    }

    &:hover {
      color: $primary1;
    }
  }

  &__redirection-link {
    margin: getEm(40px, $textFz) 0 0 0;
    text-align: center;
  }

  &__input-field {
    $fz: 16px;

    &:not(:first-child){
      margin: getEm(24px, $fz) 0 0 0;
    }

    & .input {
      &__node {
        padding: getEm(12px, $fz);
        height: getEm(48px, $fz);
        font-size: $fz;
        border-width: getEm(1px, $fz);
      }

      &__label {
        @extend .auth-form__text-font;
        margin: 0 0 getEm(6px, $fz) 0;
      }

      &__error {
        $fz: 11 / 13 * $fz;
        font-size: $fz;
      }

      &__close-button {
        font-size: 16px;
      }
    }
  }

  &__form-error {
    @extend .auth-form__text-font;
    @extend .auth-form__form-item;
    color: $systemDanger1;
  }

  &__checkbox {
    @extend .auth-form__form-item;
    display: flex;
    align-items: center;
    height: getEm(24px, $textFz);
    cursor: pointer;
  }

  &__checkbox-label {
    @extend .auth-form__text-font;
    margin: 0 0 0 getEm(12px, $textFz);
  }

  &__button {
    $fz: 16px;
    width: 100%;
    height: getEm(48px, $fz);
    font: 600 16px/getEm(16px) $fontNotoSans;
    color: $neutrals6;

    &:not(:first-child){
      margin: getEm(24px, $fz) 0 0 0;
    }
  }

  &__title {
    $fz: 32px;
    font: 700 $fz/getEm(38px, $fz) $fontNotoSans;
    color: $neutrals6;
  }

  &__description {
    padding: getEm(16px, $textFz) 0 0 0;
    color: $neutrals6;
  }
}
@import '@assets/utils';
@import '@assets/scss/general/text';

.tabled-object {
  &__title-container {
    padding: 0 getEm(16);
  }

  &__title {
    @extend .medium-header-font;
    @extend .medium-header-font_color_gray;
  }

  &__table {
    margin: getEm(16) 0 0 0;
    border-top: getEm(1) solid $neutrals4;
    border-bottom: none;
  }
}
@import "@assets/utils";

.drop-down {
  &:hover {
    .drop-down__arrow {
      path {
        stroke: $neutrals4;
      }
    }
  }

  &_dark {
    .drop-down__arrow {
      path {
        stroke: $neutrals1;
      }
    }

    &:hover {
      .drop-down__arrow {
        path {
          stroke: $neutrals1;
        }
      }
    }
  }

  &_open {
    .drop-down__arrow {
      transform: rotateX(180deg);
    }
  }

  &__arrow {
    transition: transform 0.2s ease-in-out;

    path {
      transition: stroke 0.1s ease-in-out;
      stroke: $neutrals3;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: getEm(26);
  }

  &__name {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__indicators {
    display: flex;
    align-items: center;
  }

  &__items-container {
    overflow: hidden;
  }

  &__nested-link {
    padding-left: getEm(64);
  }

  &__items {
    transition: margin 0.2s ease-in-out;
  }
}
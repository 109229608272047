@import "@assets/utils";

.main {
  display: flex;
  background: $neutrals5;

  &__content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-width: calc(100% - 16em);
    max-height: 100vh;
  }

  &__fragment-container {
    flex-grow: 1;
    padding: getEm(16px) getEm(24px) getEm(24px) getEm(24px);
    box-sizing: border-box;
    max-height: calc(100% - #{getEm(56px)});
  }
}
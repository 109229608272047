@import "@assets/utils";
@import "@assets/scss/general/text";

.block-property {
  padding: getEm(16px) 0;
  border-radius: getEm(8px);
  background: $neutrals6;

  &__content {
    display: flex;
    padding: 0 getEm(16px);
  }

  &__icon {
    margin: 0 getEm(16px) 0 0;
    padding: getEm(12px);
    min-width: getEm(24px);
    border-radius: getEm(8px);

    path {
      stroke: inherit;
      fill: inherit;
    }
  }

  &__label {
    font: 600 13px/20px $fontNotoSans;
    color: $neutrals2;
  }

  &__value {
    font: 600 20px/28px $fontNotoSans;
    color: $neutrals1;
  }

  &__footer {
    margin: getEm(12px) 0 0 0;
    padding: getEm(12px) getEm(16px) 0 getEm(16px);
    border-top: getEm(1px) solid $neutrals5;
  }

  &__comment {
    @extend .default-text-font;
  }
}
@import '@assets/utils';

.arrow-button {
  user-select: none;
  cursor: pointer;
  display: block;

  path {
    transition: stroke 0.3s ease-in-out;
    stroke: $neutrals2;
  }

  &:hover path {
    stroke: $neutrals1;
  }

  &_disabled path, &_disabled:hover path {
    stroke: $neutrals3;
  }

  &_direction {
    &_top {
      transform: rotateZ(180deg);
    }
    &_left {
      transform: rotateZ(90deg);
    }
    &_right {
      transform: rotateZ(-90deg);
    }
    &_bottom {}
  }
}
@import '@assets/utils';

.checkbox-container {
  display: block;

  &__checkbox {
    position: absolute;
    display: block;
    opacity: 0;
    z-index: -1;

    & + *{
      &::before {
        content: '';
        display: block;
        box-sizing: border-box;
        width: getEm(18px);
        height: getEm(18px);
        border: getEm(2px) solid $neutrals3;
        border-radius: getEm(3px);
        transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out;
        cursor: pointer;
      }
    }

    &:checked {
      & + *{
        &::before {
          border: getEm(2px) solid $primary2;
          background: $primary2 url("/assets/svg/checkbox-mark.svg") no-repeat center;
        }
      }
    }
  }

  &__checkmark {
    display: block;
  }
}
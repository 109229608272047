@import '@assets/utils';
@import "@assets/scss/general/text";

.invoice-checkbox {
  display: flex;
  border-top: getEm(1px) solid $neutrals4;
  cursor: pointer;

  &:last-child {
    border-bottom: getEm(1px) solid $neutrals4;
  }

  &__text {
    $fontSize: 13px;
    font: $fontSize/getEm(20px, $fontSize) $fontNotoSans;
    color: $neutrals2;
  }

  &__checkbox-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: getEm(15px);
  }

  &__text-item {
    padding: getEm(14px) getEm(16px);
    box-sizing: border-box;

    &_value_name {
      min-width: getEm(322px);
    }

    &_value_index {
      min-width: getEm(152px);
    }

    &_value_price {
      min-width: getEm(150px);
    }
  }

  &__availability-message {
    margin: 0 0 getEm(24px) 0;
  }
}

.small-connect-invoice-popup {

  &__message {
    @extend .dark-default-text-font;
  }

  &__open-amount {
    @extend .dark-header-text-font;
    color: $neutrals1;
  }

  &__footer {
    margin: getEm(24) 0 0 0;
  }
}

.connect-invoice-popup {

  &__table {
    flex: 1 1 auto;
    // checkbox row = 48px, form row = 56px, additional border = 1px, inline fix = 4px
    // there are 10 elements and 1 header row + 1 form row
    height: #{getEm((48px + 1px) * (10 + 1) + 56px + 4px)};
    border-top: getEm(1px) solid $neutrals4;
  }

  &__checkbox-cell {
    width: getEm(48px);
  }

  &__receiver-cell {
    width: getEm(322px);
  }

  &__invoice-number-cell {
    width: getEm(152px);
  }

  &__amount-cell {
    width: getEm(150px);
  }

  &__pagination {
    padding: getEm(24px) 0;
  }
}

.small-connect-invoice-popup, .connect-invoice-popup {

  &__container {
    width: 720px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-height: calc(95vh - #{getEm(80px + 90px)});
  }

  &__footer {
    justify-content: flex-start;
  }

  &__footer-button {
    &:not(:first-child) {
      margin: 0 0 0 getEm(16px);
    }
  }
}
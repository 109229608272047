@import '@assets/utils';
@import '@assets/scss/general/scroll';

.tabled-list {
  @extend .custom-scrollbar;

  &__object {
    &:not(:first-child) {
      margin: getEm(22) 0 0 0;
    }

    &:not(:last-child) {
      border-bottom: getEm(1) solid $neutrals4;
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
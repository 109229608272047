@import "@assets/utils";

$fontSize: 13px;

.field-container-position {
  position: relative;
}

.field-border {
  border: getEm(1px, $fontSize) solid $neutrals4;
  border-radius: getEm(8px, $fontSize);
  outline: none;
  transition: border 0.3s ease-in-out;
}

.field-padding {
  padding: getEm(9px, $fontSize) getEm(40px, $fontSize) getEm(9px, $fontSize) getEm(12px, $fontSize);
  box-sizing: border-box;
}

.field-value-font {
  font: $fontSize/getEm(20px, $fontSize) $fontNotoSans;
  color: $neutrals2;
}

.field-placeholder-font {
  font: $fontSize/getEm(20px, $fontSize) $fontNotoSans;
  color: $neutrals3;
}

.field-button-color{
  path {
    transition: stroke 0.3s ease-in-out;
    stroke: $neutrals4;
  }
}

.field-button-visibility {
  visibility: hidden;

  &_visible {
    animation: showButton 0.3s forwards;
  }

  &_hidden {
    animation: hideButton 0.3s forwards;
  }
}

.hovered-field-button-color {
  path {
    stroke: $neutrals3;
  }
}

.field-icon-color {
  path {
    stroke: $neutrals3;
  }
}

.field-icon-position, .field-button-position {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.field-icon-padding, .field-button-padding {
  padding: getEm(8px, $fontSize) getEm(12px, $fontSize) getEm(8px, $fontSize) getEm(8px, $fontSize);
}

.field-icon-size, .field-button-size {
  width: getEm(24px, $fontSize);
  height: getEm(24px, $fontSize);
}

.field-error-container-margin {
  margin: getEm(4px) 0 0 0;
}

@keyframes showButton {
  from {
    visibility: hidden;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
  }
}

@keyframes hideButton {
  from {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}
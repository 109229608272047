@import '@assets/utils';

.open-payments {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 getEm(28px) 0;
  }

  &__table-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 0.125em 0 0.125em;
    border-radius: getEm(8px);
    background: $neutrals6;
    overflow: hidden;
  }

  &__table {
    flex-grow: 1;
  }

  &__status-cell {
    width: getEm(160px);
  }

  &__receiver-cell {
    width: getEm(180px);
  }

  &__amount-cell {
    width: getEm(150px);
  }

  &__description-cell {
    width: getEm(200px);
  }

  &__usage-cell {
    width: getEm(200px);
  }

  &__invoice-preview-cell {
    width: auto;
  }

  &__confirm-payments-cell {
    width: getEm(238px);
  }
}
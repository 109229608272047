@import "@assets/utils";

.navigation {
  &__sidebar {
    display: flex;
    flex-direction: column;
    padding: getEm(16) 0 getEm(20) 0;
    box-sizing: border-box;
    width: getEm(256);
    min-width: getEm(256);
    min-height: 100vh;
    background: $neutrals1;
  }

  &__header {
    margin: 0 0 getEm(24) 0;
  }

  &__logo {
    margin: 0 0 0 getEm(32);
    width: getEm(84);
    height: getEm(32);
  }

  &__nav {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    height: 100%;
  }

  &__link {
    position: relative;
    z-index: 10;
  }

  &__backdrop {
    position: absolute;
    right: 0;
    z-index: 2;
  }
}

.backdrop {
  width: 100%;
  transition:
          top 0.2s ease-in-out,
          height 0.2s ease-in-out;

  &__body {
    position: relative;
    float: right;
    width: calc(100% - #{getEm(20px)});
    height: 100%;
    border-top-left-radius: getEm(24px);
    border-bottom-left-radius: getEm(24px);
    background: $neutrals5;
    z-index: 2;
  }

  &__outer-border {
    $outHeight: 28 * 2;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: getEm(28px);
    height: calc(100% + #{getEm($outHeight)});
    background: $neutrals5;
    z-index: 1;

    &::before, &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: getEm($outHeight);
      background: $neutrals1;
    }

    &::before {
      top: 0;
      transform: translateY(-50%);
      border-bottom-right-radius: getEm(28px);
    }

    &::after {
      bottom: 0;
      transform: translateY(50%);
      border-top-right-radius: getEm(28px);
    }
  }
}
@import '@assets/utils';

.log-popup {
  &__container {
    display: flex;
    flex-direction: column;
    width: getEm(720px);
    min-height: getEm(200px);
  }

  &__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: hidden;
  }

  &__header {
    border-bottom: getEm(1) solid $neutrals4;
  }

  &__table {
    margin: 0 0 getEm(24) 0;
    border-top: none;

    .table__row {
      &:first-child {
        margin: getEm(24) 0 0 0;
      }
    }
  }
}
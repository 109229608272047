@import '@assets/utils';
@import '@assets/scss/general/text';

.block-message {
  position: relative;
  padding: getEm(16px) getEm(16px) getEm(16px) getEm(48px);
  box-sizing: border-box;
  border-radius: getEm(8px);

  &_type {
    &_error {
      background: $systemDanger2;

      .block-message {
        &__icon path, &__close-button path {
          stroke: $systemDanger1;
        }

        &__title {
          color: $systemDanger1;
        }
      }
    }

    &_attention {
      background: $systemWarning2;

      .block-message {
        &__icon path, &__close-button path {
          stroke: $systemWarning1;
        }

        &__title {
          color: $systemWarning1;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    left: getEm(16px);
    top: getEm(16px)
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    $fontSize: 16px;
    font: 600 $fontSize/getEm(22px, $fontSize) $fontNotoSans;
  }

  &__content {
    margin: getEm(16px) 0 0 0;
  }

  &__text {
    @extend .dark-default-text-font;
    display: flex;
    flex-direction: column;
  }

  &__paragraph {

  }

  &__close-button {
    cursor: pointer;
  }
}
@import '@assets/utils';
@import '@assets/scss/general/text';

.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__content {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    margin: getEm(24) 0 0 0;
    overflow: hidden;
  }

  &__left-block, &__right-block {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: space-between;
    max-width: calc(50% - 12px);
    height: 100%;
    overflow: hidden;
  }

  &__section {
    border-radius: getEm(8);
    background: $neutrals6;
  }

  &__section-header {
    display: flex;
    justify-content: space-between;
    padding: getEm(16);
  }

  &__section-title {
    @extend .large-header-font;
    @extend .large-header-font_color_dark;
  }

  &__section-action-button {
    margin: getEm(-6) 0 0 0;
  }

  &__section-footer {
    padding: 0 getEm(16) getEm(16) getEm(16);
  }

  &__claims {
    display: flex;
    justify-content: space-between;
    margin: getEm(16) 0 0 0;
  }

  &__claim-item {
    flex: 1 1 auto;
    max-width: calc(50% - #{getEm(8)});
  }

  &__missing-documents-section {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: hidden;
  }

  &__missing-documents-section, &__history-section {
    margin: getEm(24) 0 0 0;
  }

  &__missing-documents, &__history-section, &__monitoring-section {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 0 getEm(2) 0 getEm(2);
    overflow: hidden;
  }

  &__monitoring-section {
    flex: 0 1 auto;

    .dashboard__section-header{
      padding: getEm(16) getEm(16) getEm(10) getEm(16);
    }
  }

  &__history-section {
    max-height: calc(100% - #{getEm(278 + 24)});
  }

  &__missing-documents, &__monitoring-list {
    border-top: getEm(1) solid $neutrals4;
  }

  &__monitoring-list {
    padding: getEm(16) 0 0 0;
    overflow: auto;
  }

  &__missing-documents-table {
    flex: 1 1 auto;
  }

  // 400px - 4px
  &__employee-cell {
    width: getEm(396);
  }

  &__missing-files-cell {
    width: getEm(156);
  }

  &__history-section, &__monitoring-section  {
    padding: 0 getEm(2) getEm(2) getEm(2);
  }

  &__history {
    border-top: getEm(1) solid $neutrals4;
    border-bottom: none;
  }

  &__history-name-cell {
    width: getEm(136);
  }

  // 270 - 4
  &__history-action-cell {
    width: getEm(266);
  }

  &__history-date-cell {
    width: getEm(150);
  }
}
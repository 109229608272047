@import '@assets/utils';
@import '@assets/scss/general/scroll';

.view-invoice-popup-container{
  &_maximized {
    height: 1000px;

    .view-invoice-popup {
      flex: 1 1 auto;
      max-height: calc(100% - #{getEm(80px + 24px + 40px)}); // popup header + content padding

      &__layout {
        padding: getEm(24px) 0 0 0;
      }

      &__pdf-viewer {
        width: getEm(600px);
      }
    }
  }
}

.view-invoice-popup {
  padding: 0 getEm(24px);
  border-top: getEm(1px) solid $neutrals4;

  &__layout {
    display: flex;
    padding: getEm(24px) 0 getEm(40px) 0;
    min-height: 100%;
  }

  &__pdf-container {
    position: relative;
    padding: getEm(8px) 0 getEm(8px) getEm(8px);
    box-sizing: border-box;
    width: getEm(414px);
    border-radius: getEm(8px);
    background: $neutrals5;
    overflow: hidden;
  }

  &__pdf-scroll-container {
    @extend .custom-scrollbar;
    position: absolute;
    width: calc(100% - #{getEm(8px)});
    height: calc(100% - #{getEm(16px)});
    overflow-x: auto;
    overflow-y: scroll;
  }

  &__pdf {
    position: absolute;
    width: 100%;

    div {
      &:not(:first-child) {
        margin: getEm(8px) 0 0 0;
      }
    }

    canvas {
      display: block;
      width: 100%;
    }
  }

  &__info-container {
    display: flex;
    margin: 0 0 0 getEm(24px);
  }

  &__info {
    width: getEm(300px);
  }

  &__info-item {
    &:not(:first-child) {
      margin: getEm(12px)  0 0 0;
    }
  }

  &__table-container {
    margin: 0 0 0 getEm(24px);
  }

  &__table {
    min-width: getEm(230px);
  }
}
@import '@assets/utils';
@import '@assets/scss/general/text';

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__previous-button, &__next-button {
    padding: getEm(4px);
  }

  &__label {
    $fontSize: 13px;
    margin: 0 getEm(16px, $fontSize) 0 0;
    font: $fontSize/getEm(15px, $fontSize) $fontNotoSans;
    color: $neutrals2;
  }

  &__buttons-container {
    display: flex;

    &_hidden {
      visibility: hidden;
    }
  }

  &__number-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: getEm(32px);
    height: getEm(32px);
    font-size: 16px;
    border: none;
    border-radius: getEm(8px);
    background: none;
    outline: none;
    user-select: none;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;

    &_active {
      background: $primary5;

      .pagination__number-button-text {
        color: $primary2;
      }
    }
  }

  &__number-button-text {
    @extend .default-text-font;
    transition: color 0.3s ease-in-out;
  }

  &__hidden-buttons-sign {
    display: flex;
    justify-content: center;
    align-items: center;
    width: getEm(32px);
    height: getEm(32px);
    user-select: none;
  }

  &__hidden-buttons-sign-text {
    @extend .default-text-font;
    cursor: default;
  }
}
@import '@assets/utils';

.auth-screen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  padding: getEm(12px) getEm(120px);
  box-sizing: border-box;
  background: $neutrals5 url("./../../assets/svg/pattern.svg") no-repeat center right 75px/auto;

  &__form {
    width: getEm(590px);
    max-width: 100%;
    flex: 0 0 auto;
  }

  &__illustration {
    flex: 0 1 auto;
    margin: 0 0 0 getEm(32px);
  }

  @media (max-width: $point1400) {
    justify-content: center;
    padding: getEm(12px);

    &__illustration {
      display: none;
    }
  }

  @media (max-width: $point768) {
    &__form {
      width: getEm(400px);

      & .auth-form {
        &__title {
          $fz: 22px;
          font: 700 22px/getEm(26px, $fz) $fontNotoSans;
        }

        &__header {
          padding: getEm(24px) getEm(16px);
        }

        &__form {
          padding: getEm(24px) getEm(16px);
        }

        &__redirection-link {
          margin: getEm(24px, 16px) 0 0 0;
        }
      }
    }
  }
}

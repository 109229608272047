@import '@assets/utils';
@import '@assets/scss/general/text';
@import '@assets/scss/general/scroll';

.confirm-payments {
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-items: center;

  &__users-container {
    @extend .custom-scrollbar;
    display: flex;
    overflow-x: auto;
  }

  &__user{
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    width: getEm(32px);
    height: getEm(32px);
    border-radius: 100%;
    background: $neutrals3;
    cursor: default;


    &:not(:first-child) {
      margin: 0 0 0 getEm(16px);
    }

    &_has-confirmed {
      background: $systemSuccess1;
    }

    &_style_cursor-pointer {
      cursor: pointer;
    }
  }

  &__user-label {
    @extend .light-header-text-font;
    color: $neutrals6;
  }

  &__buttons-container {
    flex: 0 0 auto;
    padding: 0 0 0 getEm(8px);
  }

  &__button {
    font-size: 16px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;

    &:not(:last-child) {
      margin: 0 getEm(16px) 0 0;
    }

    svg {
      display: block;
    }
  }
}
@import '@assets/utils';
@import '@assets/scss/general/text';

.delete-payment-popup {
  &__content {
    margin: getEm(16px) 0;
  }

  &__question {
    @extend .dark-default-text-font;
  }

  &__button {
    flex: 1 0 auto;

    &:not(:first-child) {
      margin: 0 0 0 getEm(16px);
    }
  }
}
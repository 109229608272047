@import '@assets/utils';
@import '@assets/scss/general/text';

.error-popup {
  &__container {
    width: getEm(500px);
    max-width: calc(100% - #{getEm(32px)});
    background: $systemDanger2;
  }

  &__content {
    margin: 0 0 getEm(16px) 0;
    padding: 0 getEm(16px) 0 getEm(48px);
  }

  &__question {
    @extend .dark-default-text-font;
  }

  &__footer {
    justify-content: flex-end;
  }

  &__icon path{
    stroke: $systemDanger1;
  }
}

.error-popup .popup {
  &_small {
    & .error-popup {
      &__header {
        padding: getEm(16px) getEm(16px) getEm(16px) getEm(48px);
      }
    }
  }

  &__title {
    color: $systemDanger1;
  }

  &__close-button {
    path {
      stroke: $systemDanger1
    }

    &:hover path {
      stroke: darken($systemDanger1, 20%);
    }
  }

  &__loader {
    display: none;
  }
}
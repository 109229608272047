@import "@assets/utils";
@import "@assets/scss/general/text";

.transactions {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    margin: 0 0 getEm(24px) 0;
  }

  &__balance {
    align-self: flex-start;
    margin: 0 0 getEm(16px) 0;
    width: 20em;
  }

  &__balance-icon {
    background: $primary5;
    stroke: $primary2;
  }

  &__last-import-date {
    @extend .dark-header-text-font;
  }

  &__table-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: getEm(8px);
    background: #fff;
    overflow: hidden;
  }

  &__table-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 getEm(2px) 0 getEm(2px);
    overflow: hidden;
  }

  &__table {
    flex-grow: 1;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: getEm(24px);
    border-bottom: getEm(1px ) solid $neutrals4;
  }

  &__buttons-container {
    display: flex;
  }

  &__header-button {
    &:not(:first-child) {
      margin: 0 0 0 getEm(14px);
    }
  }

  &__index-cell{
    width: getEm(54px);
  }

  &__invoice-cell{
    width: getEm(84px);
  }

  &__booking-cell{
    // originally 144px (compensating table-wrapper padding)
    width: getEm(140px);
  }

  &__created-at {
    width: getEm(144px);
  }

  &__name-cell{
    width: getEm(180px);
  }

  &__usage-cell{
    width: getEm(240px);
  }

  &__date-cell{
    width: getEm(164px);
  }

  &__price-cell{
    width: getEm(135px);
  }

  &__left-price-cell{
    width: getEm(135px);
  }

  &__loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
$neutrals1: #161F32;
$neutrals2: #4E5461;
$neutrals3: #98A0B2;
$neutrals4: #e6e7eb;
$neutrals5: #f5f6fa;
$neutrals6: #ffffff;

$primary1: #4235CC;
$primary2: #5F3EF3;
$primary3: #8266FF;
$primary4: #C0B2FF;
$primary5: #EAE5FF;

$systemSuccess1: #00E083;
$systemSuccess2: #D6FFEE;
$systemWarning1: #FCB83A;
$systemWarning2: #FEF3E0;
$systemDanger1: #FF5B32;
$systemDanger2: #FFDED6;
$systemInfo1: #4DE1FF;
$systemInfo2: #D6F8FF;
@import '@assets/utils';

.custom-scrollbar {
  &::-webkit-scrollbar {
    background: $neutrals5;
  }

  &::-webkit-scrollbar:vertical {
    width: getEm(20px);
  }

  &::-webkit-scrollbar:horizontal {
    height: getEm(20px);
  }

  &::-webkit-scrollbar-thumb {
    background: $neutrals3;
    border-radius: getEm(10px);
    border: getEm(6px) solid $neutrals5;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $neutrals2;
  }
}

@import "@assets/utils";
@import "@assets/scss/general/text";

.link {
  display: flex;
  padding: getEm(12) 0 getEm(12) getEm(36);
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  &:hover {
    .link__text {
      color: $neutrals4;
    }

    .link__icon {
      path, rect, circle{
        stroke: $neutrals4;
      }
    }
  }

  &_dark {
    .link{
      &__text {
        color: $neutrals2;
      }

      &__icon {
        path, rect, circle {
          stroke: $neutrals2;
        }
      }
    }

    &:hover, .link_active {
      .link__text {
        color: $neutrals1;
      }

      .link__icon {
        path, rect, circle {
          stroke: $neutrals1;
        }
      }
    }

    &.link_active {
      .link__text {
        color: $neutrals1;
      }

      .link__icon {
        path, rect, circle {
          stroke: $neutrals1;
        }
      }
    }
  }

  &__text {
    display: block;
    flex: 0 1 auto;
    margin: 0 0 0 getEm(12);
    font: 600 getEm(16)/getEm(22) $fontNotoSans;
    color: $neutrals3;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: color 0.1s ease-in-out;
  }

  &__icon {
    flex: 0 1 getEm(24);

    path, rect, circle{
      transition: stroke 0.1s ease-in-out;
      stroke: $neutrals3;
    }
  }

  &__counter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 getEm(12) 0 0;
    width: getEm(20);
    height: getEm(20);
    border-radius: getEm(10);
    background: $primary2;
  }

  &__counter-number {
    @extend .small-header-font;
    @extend .small-header-font_color_white;
  }
}
@import '@assets/utils';

.loader {
  //position: relative;

  &_size {
    $defaultSize: 120px;
    $defaultThickness: 8px;

    $mediumSize: 70px;
    $mediumThickness: $defaultThickness * ($mediumSize / $defaultSize);

    $smallSize: 50px;
    $smallThickness: $defaultThickness * ($smallSize / $defaultSize);

    &_large {
      width: getEm($defaultSize);

      .loader__outer-spinner, .loader__inner-spinner {
        --rcs-ring-thickness: #{$defaultThickness};
      }
    }

    &_medium {
      width: getEm($mediumSize);
      padding: getEm(32px);

      .loader__outer-spinner, .loader__inner-spinner {
        --rcs-ring-thickness: #{$mediumThickness};
      }
    }

    &_small {
      width: getEm($smallSize);
      padding: getEm(16px);

      .loader__outer-spinner, .loader__inner-spinner {
        --rcs-ring-thickness: #{$smallThickness};
      }
    }
  }

  &__content {
    width: 100%;
    padding: 0 0 100% 0;
    position: relative;
  }

  .loader__outer-spinner-container, .loader__inner-spinner-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .loader__inner-spinner-container {
    width: calc(100 / 120 * 100%);
    height: calc(100 / 120 * 100%);
  }

  .loader__outer-spinner-container {
    width: 100%;
    height: 100%;
  }

  .loader__outer-spinner, .loader__inner-spinner {
    width: 100%;
    height: 100%;
  }

  .loader__inner-spinner {
    transform: rotateZ(-180deg);

    div {
      animation: rcs-ring 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
  }
}
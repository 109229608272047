@import '@assets/utils';

.bookings {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;

  &__categories {
    display: flex;
    justify-content: space-between;
    margin: getEm(24px) 0 0 0;
  }

  &__wage-category-icon {
    background: $systemSuccess2;
    stroke: $systemSuccess1;
  }

  &__transport-category-icon,  &__poco-category-icon {
    background: $systemDanger2;
    fill: $systemDanger1;
  }

  &__garage-category-icon {
    background: $systemDanger2;
    stroke: $systemDanger1;
  }

  &__table-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin: getEm(16px) 0 0 0;
    padding: 0 getEm(2);
    border-radius: getEm(8px);
    background: $neutrals6;
    overflow: hidden;
  }

  &__paginated-table {
    display: flex;
    flex: 1 1 auto;
    // todo: consider adding the property into paginated-table class
    flex-direction: column;
    overflow: hidden;
  }

  &__table {
    flex: 1 1 auto;
  }

  &__index-cell {
    width: getEm(54px);
  }

  &__status-cell {
    width: getEm(98px);
  }

  &__invoice-cell {
    width: getEm(100px);
  }

  &__receipt-cell {
    width: getEm(110px);
  }

  &__bank-name-cell {
    width: getEm(110px);
  }

  &__usage-cell {
    width: getEm(130px);
  }

  &__date-cell {
    width: getEm(164px);
  }

  &__net-amount-cell {
    //  2px for left border
    width: getEm(120px - 2px);
  }

  &__gross-amount-cell {
    // 2px for right border
    width: getEm(130px - 2px);
  }

  &__receipt-type-cell {
    width: getEm(120px);
  }
}
@import '@assets/utils';

.top-bar {
  display: flex;
  justify-content: flex-end;
  padding: getEm(12px);
  box-sizing: border-box;
  width: 100%;
  height: getEm(56em);
  min-height: getEm(56em);
  z-index: 1;

  &__language-select{
    padding: 0 getEm(12px);
    height: 100%;
  }
}
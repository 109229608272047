@import '@assets/utils';
@import '@assets/scss/general/text';

.primary-text {
  padding: getEm(12) getEm(16);
  border-radius: getEm(8);
  background: $primary5;

  &__text {
    @extend .large-header-font;
    @extend .large-header-font_color_primary;
  }
}
@import "@assets/utils.scss";
@import "@assets/scss/general/field";
@import "@assets/scss/general/text";
@import '@assets/scss/general/scroll';

.select {
  &__label {
    @extend .input-label;
  }

  &__error-container {
    @extend .field-error-container-margin;
  }

  &__error {
    @extend .error-text-font;
  }
}

.react-select {
  & .react-select {

    &__input input {
      @extend .field-value-font;
    }

    &__control {
      @extend .field-padding;
      @extend .field-border;
      padding: getEm(9px, $fontSize) getEm(40px + 32px, $fontSize) getEm(9px, $fontSize) getEm(12px, $fontSize);
      font-size: $fontSize;
      box-shadow: none;
      cursor: pointer;

      &:hover {
        border: getEm(1px, $fontSize) solid $neutrals4;
      }

      &--menu-is-open, &--menu-is-open:hover {
        border: getEm(1px, $fontSize) solid $primary1;
      }
    }

    &__value-container {
      padding: 0;
      height: getEm(20px, $fontSize);

      & > div:not(.react-select__single-value) {
        margin: 0;
        padding: 0;
      }
    }

    &__input, &__single-value {
      @extend .field-value-font;
      margin: 0;
      padding: 0;
      min-width: 100%;
      font-size: $fontSize;
    }

    &__placeholder {
      @extend .field-placeholder-font;
      margin: 0;
      font-size: $fontSize;
    }

    &__indicator{
      padding: 0;
    }

    &__indicator-separator {
      display: none;
    }

    &__clear-indicator {
      display: none;
    }

    &__dropdown-indicator{
      & .select__chevron {
        @extend .field-icon-position;
        @extend .field-icon-padding;
        @extend .field-icon-color;
        @extend .field-icon-size;
        padding: getEm(8px, $fontSize) getEm(12px, $fontSize) getEm(8px, $fontSize) getEm(4px, $fontSize);
        font-size: $fontSize;
      }
    }

    &__close-button {
      @extend .field-button-position;
      @extend .field-button-padding;
      @extend .field-button-color;
      @extend .field-button-size;
      @extend .field-button-visibility;
      right: getEm(40px, $fontSize);
      padding: getEm(8px, $fontSize) getEm(4px, $fontSize);

      &:hover {
        @extend .hovered-field-button-color;
      }

      &_visible {
        @extend .field-button-visibility_visible;
      }

      &_hidden {
        @extend .field-button-visibility_hidden;
      }
    }

    &__menu {
      margin: getEm(8px) 0 0 0;
      min-width: 6em;
      box-shadow: none;
    }

    &__menu-list {
      @extend .custom-scrollbar;
      padding: getEm(8px, $fontSize) 0;
      border-radius: getEm(8px, $fontSize);
      box-shadow: 0 getEm(8px, $fontSize) getEm(8px, $fontSize) 0 rgba(#000, 0.08);
    }

    &__option {
      padding: getEm(6px, $fontSize) getEm(12px, $fontSize);
      font: $fontSize/getEm(20px, $fontSize) $fontNotoSans;
      color: $neutrals2;
      cursor: pointer;

      &:hover, &--is-focused  {
        background: $neutrals5;
      }

      &--is-selected{
        background: $neutrals4;
      }
    }

    &__loading-indicator, &__indicator-separator {
      display: none;
    }

    &__menu-notice--no-options, &__menu-notice--loading {
      @extend .field-placeholder-font;
      padding: 0 getEm(12px, $fontSize);
    }
  }

  &_with-error {
    & .react-select {
      &__control {
        border-color: $systemDanger1;

        &:hover {
          border-color: $systemDanger1;
        }

        &--menu-is-open, &--menu-is-open:hover {
          border-color: $systemDanger1;
        }
      }
    }
  }
}
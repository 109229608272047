@import '@assets/utils';

.global-loader {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $neutrals1;
  z-index: 100;
}

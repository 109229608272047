
@import '@assets/utils';

.invoice-links {
  $fontSize: 13px;

  &__link {
    display: block;
    max-width: 100%;
    font: #{$fontSize}/ 20px $fontNotoSans;
    color: $neutrals2;
    white-space: nowrap;
    text-decoration: underline;
    text-overflow: ellipsis;
    overflow:hidden;

    &:not(:last-child) {
      margin-bottom: getEm(4px, $fontSize);
    }
  }

  &__button {
    display: block;
    padding: 0;
    font-size: 13px;
    border: none;
    background: none;
    outline: none;
    user-select: none;
    cursor: pointer;

    svg {
      width: getEm(24px, $fontSize);
      height: getEm(24px, $fontSize);
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background: $primary5;
      }
    }
  }
}
@import '@assets/utils';
@import '@assets/scss/general/text';

.output-field {
  &__label {
    @extend .default-text-font;
    margin: 0 0 getEm(8px, $defaultTextFontSize) 0;
  }

  &__value-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: getEm(10px) getEm(12px);
    box-sizing: border-box;
    min-height: getEm(40px);
    border-radius: getEm(8px);
    background: $neutrals5;
  }

  &__value {
    @extend .default-text-font;
  }

  &__icon-container {
    display: flex;
    align-items: center;
    height: getEm(20px);
  }

  &__icon {
    * {
      stroke: $neutrals3;
    }
  }
}
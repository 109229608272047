@import '@assets/utils';
@import '@assets/scss/general/text';

.invoice-card {
  padding: getEm(16px);
  box-sizing: border-box;
  border: getEm(1) solid $neutrals4;
  border-radius: getEm(8);

  &__title {
    @extend .dark-header-text-font;
  }

  &__price {
    @extend .large-header-font;
    @extend .large-header-font_color_dark;
  }

  &__button-container {
    margin: getEm(12) 0 0 0;
  }
}
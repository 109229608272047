@import '@assets/utils';
@import '@assets/scss/general/scroll';

.pdf-viewer {
  position: relative;
  padding: getEm(8px) 0 getEm(8px) getEm(8px);
  box-sizing: border-box;
  width: getEm(414px);
  border-radius: getEm(8px);
  background: $neutrals5;
  overflow: hidden;

  &__scroll-container {
    @extend .custom-scrollbar;
    position: absolute;
    width: calc(100% - #{getEm(8px)});
    height: calc(100% - #{getEm(16px)});
    overflow-x: auto;
    overflow-y: scroll;
  }

  &__pdf {
    position: absolute;
    width: 100%;

    &_hidden {
      visibility: hidden;
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__canvas {
    display: block;
    width: 100%;
    cursor: zoom-in;


    &:not(:first-child) {
      margin: getEm(8px) 0 0 0;
    }
  }

  &__magnifier-container {
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__magnifier{
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    padding: getEm(4px);
    box-sizing: border-box; border-radius: 100%; background: $neutrals5; cursor: none;
  }
}
@import '@assets/utils';

$smallHeaderFontSize: 10px;
$defaultTextFontSize: 13px;
$darkHeaderTextFontSize: 13px;
$lightHeaderTextFontSize: 13px;
$mediumHeaderFontSize: 16px;
$largeHeaderFontSize: 20px;

.default-text-font {
  font: $defaultTextFontSize/getEm(20px, $defaultTextFontSize) $fontNotoSans;
  color: $neutrals2;
}

.dark-default-text-font {
  font: $defaultTextFontSize/getEm(20px, $defaultTextFontSize) $fontNotoSans;
  color: $neutrals1;
}

.dark-header-text-font {
  font: 600 $darkHeaderTextFontSize/getEm(20px, $darkHeaderTextFontSize) $fontNotoSans;
  color: $neutrals2;
}

.light-header-text-font {
  font: 600 $darkHeaderTextFontSize/getEm(20px, $darkHeaderTextFontSize) $fontNotoSans;
  color: $neutrals3;
}

.input-label {
  @extend .default-text-font;
  margin: 0 0 getEm(8px, $defaultTextFontSize) 0;
}

.error-text-font {
  $fontSize: 11px;
  font: $fontSize/getEm(16px, $fontSize) $fontNotoSans;
  color: $systemDanger1
}

.large-header-font {
  font: 600 $largeHeaderFontSize/getEm(28px, $largeHeaderFontSize) $fontNotoSans;

  &_color {
    &_dark {
      color: $neutrals1;
    }
    &_primary {
      color: $primary2;
    }
  }
}

.medium-header-font {
  font: 600 $mediumHeaderFontSize/getEm(22px, $mediumHeaderFontSize) $fontNotoSans;

  &_color {
    &_gray {
      color: $neutrals2;
    }
  }
}

.small-header-font {
  font: 600 $smallHeaderFontSize/getEm(20px, $smallHeaderFontSize) $fontNotoSans;

  &_color {
    &_white {
      color: $neutrals6;
    }
  }
}

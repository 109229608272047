@import '@assets/utils';
@import '@assets/scss/general/scroll';
@import '@assets/scss/general/text';

.table {
  @extend .custom-scrollbar;
  border-bottom: getEm(1px ) solid $neutrals4;
  overflow: auto;

  &__header-row, &__row {
    display: inline-flex;
    min-width: 100%;
  }

  &__header-row, &__row:not(:last-child) {
    border-bottom: getEm(1px ) solid $neutrals4;
  }

  &__cell-wrapper {
    flex-grow: 1;
    flex-shrink: 0;
    box-sizing: border-box;
    width: getEm(100px);
  }

  &__text-cell {
    padding: getEm(14px) 0 getEm(14px) getEm(16px);

    &:last-child{
      padding: getEm(14px) getEm(16px) getEm(14px) getEm(16px);
    }
  }

  &__highlighted-text-cell {
    padding: getEm(12px) 0 getEm(12px) getEm(16px);

    &:last-child{
      padding: getEm(12px) getEm(16px) getEm(12px) getEm(16px);
    }
  }

  &__net-gross-amount-cell {
    padding: getEm(14px) 0 getEm(14px) getEm(16px);
  }

  &__title-cell {
    padding: getEm(14px) getEm(16px);
  }

  &__booking-cell {
    padding: getEm(12px) 0 0 0;
  }

  &__invoice-links-cell {
    padding: getEm(14px) getEm(4px) getEm(14px) getEm(16px);
  }

  &__field-cell {
    padding: getEm(8px);
  }

  &__button-cell {
    @extend .table__field-cell;
  }

  &__confirm-payments-cell {
    padding: getEm(8px) getEm(16px);
  }

  &__checkbox-container-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: getEm(48px);
  }

  &__radio-button-container-cell {
    padding: getEm(12px);
  }

  &__title, &__text, &__net-gross-amount-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__title {
    $fontSize: 13px;
    font: 600 $fontSize/getEm(20px, $fontSize) $fontNotoSans;
    color: $neutrals3;
  }

  &__text {
    $fontSize: 13px;
    font: $fontSize/getEm(20px, $fontSize) $fontNotoSans;
    color: $neutrals2;
  }

  &__open-payment-amount, &__finished-payment-amount {
    $fontSize: 13px;
    font: 600 $fontSize/getEm(20px, $fontSize) $fontNotoSans;
  }

  &__open-payment-amount {
    color: $systemDanger1,
  }

  &__finished-payment-amount {
    color: $systemSuccess1;
  }

  &__net-gross-amount-text {
    @extend .dark-header-text-font;

    &_status_negative {
      color: $systemDanger1;
    }

    &_status_positive {
      color: $systemSuccess1;
    }
  }
}

.highlighted-text-cell {
  display: inline-flex;
  padding: getEm(5px) getEm(10px);
  border-radius: getEm(32px);
  background: $systemSuccess1;

  &__text {
    $fontSize: 13px;
    font: 600 $fontSize/getEm(20px, $fontSize) $fontNotoSans;
    color: $neutrals6;
  }
}
@import '@assets/utils';
@import '@assets/scss/general/scroll';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
  z-index: 100;

  &_small {
    .popup {
      &__title {
        $font-size: 20px;
        font: 600 $font-size/getEm(28px, $font-size) $fontNotoSans;
      }

      &__header {
        padding: getEm(16px);

        &_with_icon {
          padding-left: getEm(48px);
        }
      }

      &__footer {
        padding: getEm(16px);
      }

      &__icon {
        top: getEm(16px);
        left: getEm(16px);
      }
    }
  }

  &__content-container {
    @extend .custom-scrollbar;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - #{getEm(15px)});
    max-height: calc(100% - #{getEm(15px * 2)});
    border-radius: getEm(8px);
    background: #fff;
    overflow-y: auto;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: getEm(24px);

    &_with_icon {
      padding-left: getEm(48px);
    }
  }

  &__icon {
    position: absolute;
    top: getEm(24px);
    left: getEm(24px);
    width: getEm(24px);
  }

  &__close-button {
    user-select: none;
    cursor: pointer;

    path {
      transition: stroke 0.3s ease-in-out;
      stroke: $neutrals3;
    }

    &:hover {
      path {
        stroke: $neutrals2;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: getEm(24px);
    border-top: getEm(1px) solid $neutrals4;
  }

  &__content {
    @extend .custom-scrollbar;
    position: relative;
    flex: 0 1 auto;
    padding: 0 getEm(24px);
    max-height: calc(100% - #{getEm(80px + 90px)});

    &::before {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: $neutrals6;
      z-index: 10;
    }

    &::before, .popup__loader{
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease-in-out;
    }

    &_loading {
      &::before {
        opacity: 0.5;
        visibility: visible;
      }

      .popup__loader {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  &_active {
    display: flex;
  }

  &_visible {
    animation: visible 0.3s ease-in-out forwards;
  }
  &_hidden {
    animation: hidden 0.3s ease-in-out forwards;

    .popup__content::before, .popup__loader {
      visibility: hidden;
    }
  }
}

@keyframes visible {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes hidden {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
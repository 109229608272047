@import '@assets/utils';
@import '@assets/scss/general/text';

.create-payment-popup {

  &__container {
    width: getEm(740px);
  }

  &__header {
    border-bottom: getEm(1px) solid $neutrals4;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin: getEm(24px) 0;
    overflow: hidden;
  }

  &__field:not(:first-child) {
    margin: getEm(16px) 0 0 0;
  }

  &__table-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin: getEm(16px) 0 0 0;
    border: getEm(1px) solid $neutrals4;
    border-radius: getEm(8px);
    overflow: hidden;
    z-index: 0;
  }

  &__table {
    flex: 1 1 auto;
  }

  &__pagination {
    padding: getEm(24px) getEm(24px);
  }

  &__error-container {
    margin: getEm(4px) 0 0 0;
  }

  &__error {
    @extend .error-text-font;
  }

  &__invoice-cell {
    width: getEm(250px);
  }

  &__amount-cell {
    width: getEm(152px);
  }

  &__complete-name-cell {
    // compensate 2px of table border
    width: getEm(222px - 2px);
  }

  &__radio-button-cell {
    width: getEm(48px);
  }
}
@import '@assets/utils';
@import '@assets/scss/general/text';

.text-checkbox {
  display: flex;
  align-items: center;
  height: getEm(24px);
  cursor: pointer;

  &__label {
    @extend .default-text-font;
    display: block;
    margin: 0 0 0 getEm(8px, $defaultTextFontSize);
  }
}
@mixin font-face($font-name, $type, $weight) {
  $woff2Name: "../fonts/"+$font-name+"/"+$font-name+"-"+$type+".woff2";
  $woffName: "../fonts/"+$font-name+"/"+$font-name+"-"+$type+".woff";
  $ttfName: "../fonts/"+$font-name+"/"+$font-name+"-"+$type+".ttf";

  @font-face {
    font-family: quote($font-name);
    font-display: fallback;
    src:
            url($woff2Name),
            url($woffName),
            url($ttfName);
    font-weight: $weight;
  }
}
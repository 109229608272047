@import '@assets/utils';
@import '@assets/scss/general/text';

.section-dropdown {
  width: 100%;

  &__list {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin: getEm(-16px) 0 0 0;
    list-style: none;
  }

  &__item {
    flex: 1 1 getEm(266px);
    margin: getEm(16px) 0 0 0;
    max-width: calc(100% / 4 - #{getEm(24px * 3 / 4)});
  }

  &__rest-items-container {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  &__rest-items {
    margin: 0;
  }

  &__dropdown-button-container {
    margin: getEm(16px) 0 0 0;
  }

  &__dropdown-button {
    display: flex;
    align-items: center;
    padding: getEm(7px) getEm(16px);
    font-size: 16px;
    border: getEm(1px) solid $neutrals4;
    border-radius: getEm(8px);
    background: $neutrals6;
    outline: none;
    cursor: pointer;
  }

  &__dropdown-button-text {
    @extend .dark-header-text-font;
  }

  &__arrow {
    margin: 0 0 0 getEm(8px);
    transition: transform 0.3s ease-in-out;

    path {
      stroke: $neutrals2;
    }

    &_up {
      transform: rotateX(180deg);
    }
  }
}
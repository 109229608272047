@import '@assets/utils';
@import '@assets/scss/general/text';

.user-button {
  display: flex;
  align-items: center;
  padding: 0 getEm(12px);
  border: none;
  background: none;
  cursor: pointer;
  outline: none;

  &__icon *, &__arrow path {
    stroke: $neutrals2;
  }

  &__name {
    @extend .dark-header-text-font;
    margin: 0 0 0 getEm(6px, $darkHeaderTextFontSize);
  }

  &__arrow {
    margin: 0 0 0 getEm(6px);
  }
}
@import '@assets/utils';
@import '@assets/scss/general/text';

.confirm-popup {
  &__container {
    width: getEm(400px);
    max-width: calc(100% - #{getEm(32px)});
  }

  &__content {
    margin: getEm(16px) 0;
  }

  &__question {
    @extend .dark-default-text-font;
  }

  &__footer {
    justify-content: flex-end;
  }
}
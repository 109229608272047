@import '@assets/utils';

$fontSize: 13px;

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 getEm(16px, $fontSize);
  box-sizing: border-box;
  height: getEm(40, $fontSize);
  font: 600 $fontSize/getEm(20px, $fontSize) $fontNotoSans;
  border: none;
  border-radius: getEm(8px, $fontSize);
  outline: none;
  user-select: none;
  transition: background 0.3s ease-in-out;
  cursor: pointer;

  &_type {
    &_primary {
      color: $neutrals6;
      background: $primary2;

      &:hover {
        background: $primary1;
      }

      &:disabled {
        background: $primary2;
      }

      &.button_loading {
        background: lighten($primary2, 8%);
      }
    }

    &_dangerous {
      color: $neutrals6;
      background: $systemDanger1;

      &:hover {
        background: darken($systemDanger1, 10%);
      }

      &:disabled {
        background: $systemDanger1;
      }

      &.button_loading {
        background: lighten($systemDanger1, 8%);
      }
    }

    &_outline {
      color: $neutrals2;
      border: getEm(1px, $fontSize) solid $neutrals4;
      background: $neutrals6;

      &:hover:not(:disabled) {
        background: $neutrals5;
      }
    }

    &_primary-outline {
      color: $primary2;
      border: getEm(1px, $fontSize) solid $primary2;
      background: none;

      &:hover:not(:disabled) {
        background: $primary5;
      }
    }
  }

  &__icon,  &__loading-icon {
    margin: 0 0 0 getEm(8px, $fontSize);
    width: getEm(24px, $fontSize);
    height: getEm(24px, $fontSize);

    path {
      stroke: $neutrals6;
    }
  }

  &__loading-icon {
    animation: linear 1s infinite forwards rotate;
  }

  &:disabled, &_loading {
    cursor: default;
  }
}

@keyframes rotate {
  to {
    transform: rotateZ(360deg);
  }
}
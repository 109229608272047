@import '@assets/utils';

.with-visibility {
  &_initiallyVisible {
    visibility: visible;
  }

  &_initiallyHidden {
    visibility: hidden;
  }

  &_visible {
    animation: show 0.3s forwards;
  }

  &_hidden {
    animation: hide 0.3s forwards;
  }
}

@keyframes hide {
  from {
    visibility: visible;
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes show {
  from {
    visibility: hidden;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}
@import '@assets/utils';
@import '@assets/scss/general/text';

.output-table {
  padding: getEm(16px);
  box-sizing: border-box;
  border-radius: getEm(8px);
  background: $neutrals5;

  &__row {
    display: flex;
    justify-content: space-between;

    &:not(:first-child) {
      margin: getEm(12px) 0 0 0;
    }
  }

  &__field-name, &__field-value {
    @extend .default-text-font;
  }
}
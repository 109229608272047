@import '@assets/utils';
@import '@assets/scss/general/text';

.language-select {
  background: none;

  &__custom-option {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &_role {
      &_header {
        min-width: getEm(70px);
        height: 100%;
      }

      &_option {
        padding: getEm(6px) getEm(12px);
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background: $neutrals4;
        }
      }
    }
  }

  &__custom-option-short-title {
    @extend .dark-header-text-font;
    margin: 0 0 0 getEm(6px, $darkHeaderTextFontSize);
  }

  &__custom-menu {
    position: absolute;
    margin: getEm(8px) 0 0 0;
    padding: getEm(8px) 0;
    border-radius: getEm(8px);
    background: $neutrals6;
    box-shadow: 0 getEm(8px) getEm(8px) 0 rgba(#000, 0.08);
  }

  .language-select {
    &__control, &__control--is-focused  {
      height: 100%;
      border: none;
      background: none;
      box-shadow: none;
      min-height: auto;

      &:hover {
        border: none;
      }
    }
  }
}
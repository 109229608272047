@import '@assets/utils';

.radio-button {
  padding: getEm(2px);

  &__label {
    display: block;
    cursor: pointer;
  }

  &__input {
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;

    &:checked + .radio-button__checkmark{
      border: getEm(2px) solid $primary2;

      &::before {
        opacity: 1;
      }
    }
  }

  &__checkmark {
    position: relative;
    display: block;
    width: getEm(16px);
    height: getEm(16px);
    border: getEm(2px) solid $neutrals3;
    border-radius: getEm(10px);
    transition: border-color 0.3s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: getEm(10px);
      height: getEm(10px);
      border-radius: getEm(5px);
      background: $primary2;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  }
}